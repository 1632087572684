<template>
  <div class="mb-6">
    <wizard-widget
      v-if="isLoaded && isWizardWidgetVisible"
      :is-processing-single-video="isProcessingSingleVideo"
      :is-single-video-online="isSingleVideoOnline"
      @dismiss="dismissWidget"
    />

    <public-search-page-link v-if="isLoaded && isSingleVideoOnline" class="mt-6"/>
  </div>

  <video-empty v-if="isEmpty" @add="router.push('/add/')" />

  <template v-else>
    <div class="flex justify-between mb-4">
      <h2 class="text-xl">Videos</h2>

      <!-- <input-search v-model="search" class="max-w-lg grow" /> -->

      <div>
        <reload-button class="ml-1" @click="reloadGrid" />

        <button ref="button" class="btn-outline p-0 w-10 h-10 ml-1" @click="router.push('/add/')">
          <PhPlus class="w-4 h-4" />
        </button>
      </div>
    </div>

    <grid
      ref="videos"
      :data-handler="getVideos"
      :columns="columns"
      :row-class="rowClass"
      :show-paginator="false"
      @delete="deleteVideo"
    />
  </template>
</template>

<script setup>
import { PhPlus } from '@phosphor-icons/vue';
import Grid from '../components/Grid.vue';
import WizardWidget from '../components/WizardWidget.vue';
import PublicSearchPageLink from '../components/PublicSearchPageLink.vue';
import VideoEmpty from '../components/VideoEmpty.vue';
import VideoActions from '../components/VideoActions.vue';
import { ref, markRaw, shallowRef, onBeforeUnmount } from 'vue';
import { delete_ } from '../useApi';
import ReloadButton from '../components/ReloadButton.vue';
import { Modal } from '../useModal';
import { list as videoList, getVideoList } from '../useVideoList';
import { $error } from '../notify';
import { useRouter } from 'vue-router';
import { VIDEO_STATUS_ERROR, VIDEO_STATUS_ONLINE } from '../videoStatusConstants';

const router = useRouter();

const videos = ref(null); // ref to the grid component

const isEmpty = shallowRef(false);

const WIZARD_LOCAL_STORAGE_KEY = 'wizard';
const isWizardWidgetVisible = shallowRef(!Boolean(localStorage.getItem(WIZARD_LOCAL_STORAGE_KEY)));

function dismissWidget() {
  isWizardWidgetVisible.value = false;
  localStorage.setItem(WIZARD_LOCAL_STORAGE_KEY, 'true');
}

// const search = shallowRef(null);

function reloadGrid() {
  if (videos.value?.reload) {
    videos.value.reload();
  } else {
    isEmpty.value = false;
  }
}

/*
watchDebounced(
  search,
  reloadGrid,
  { debounce: 500, maxWait: 1000 }
);
*/

const columns = [
  {
    title: '#',
    prop: 'id',
    // sortable: true, // FIXME: add sorting at backend
    titleClass: '' // class for title cell
  },
  {
    title: 'URL',
    prop: 'url'
  },
  {
    title: 'Duration',
    prop: 'durationHr'
  },
  {
    title: 'Title',
    prop: 'title'
  },
  {
    title: 'Status',
    prop: 'status'
  },

  {
    class: 'no-animation text-right !max-w-max z-10 relative',
    component: markRaw(VideoActions)
  }
];

const rowClass = null;
// const rowClass = (_row, index) => (index % 2 ? 'bg-zinc-500/10' : ''); // stripes class for row

let videoReloadTimeout = null;

const isLoaded = shallowRef(false);

const isProcessingSingleVideo = shallowRef(false);
const isSingleVideoOnline = shallowRef(false);

async function getVideos(/* { limit, offset } */) {
  clearTimeout(videoReloadTimeout);

  await getVideoList(); // FIXME race condition

  if (videoList.value === null) {
    return null;
  }

  isLoaded.value = true;

  isEmpty.value = videoList.value.length === 0;

  if (videoList.value.find(row => row.status !== VIDEO_STATUS_ONLINE && row.status !== VIDEO_STATUS_ERROR)) {
    videoReloadTimeout = setTimeout(reloadGrid, 3000);
  }

  isProcessingSingleVideo.value = videoList.value.length === 1
    && videoList.value[0].status !== VIDEO_STATUS_ONLINE
    && videoList.value[0].status !== VIDEO_STATUS_ERROR;

  isSingleVideoOnline.value = Boolean(videoList.value.length >= 1 && videoList.value.find(v => v.status === VIDEO_STATUS_ONLINE));

  return { total: videoList.value.length, rows: videoList.value };
}

onBeforeUnmount(() => clearTimeout(videoReloadTimeout));

async function deleteVideo(row) {
  const confirm = await Modal.confirm({
    title: 'Delete video',
    body: `Are you sure you want to delete the video?`
  });

  if (!confirm) {
    return;
  }

  const result = await delete_(`/api/video/${row.id}`);

  if (!result?.success) {
    $error('Failed to delete video');
    return;
  }

  reloadGrid();
}
</script>

<template>
  <div>
    <page-header title="Add new video">
      <button class="btn-outline" @click="router.back()">Cancel</button>
    </page-header>

    <transition-appear>
      <video-add-step-one v-if="currentStep === STEP_1" @next-step="onUrlPasted" />
    </transition-appear>

    <transition-appear>
      <video-add-step-two v-if="currentStep === STEP_2" :url :video-meta @next-step="onVideoUploaded" />
    </transition-appear>

    <transition-appear>
      <video-add-step-three v-if="currentStep === STEP_3" :url :video-meta :filename @next-step="onPromptSet" />
    </transition-appear>
  </div>
</template>

<script setup>
import { shallowRef } from 'vue';
import PageHeader from '../components/PageHeader.vue';
import TransitionAppear from '../components/TransitionAppear.vue';
import VideoAddStepOne from '../components/VideoAddStepOne.vue';
import VideoAddStepTwo from '../components/VideoAddStepTwo.vue';
import VideoAddStepThree from '../components/VideoAddStepThree.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const STEP_1 = 1;
const STEP_2 = 2;
const STEP_3 = 3;

const currentStep = shallowRef(STEP_1);
const url = shallowRef('');
const filename = shallowRef(null);
const videoMeta = shallowRef(null);

function onUrlPasted({ url: _url, videoMeta: _videoMeta }) {
  url.value = _url;
  videoMeta.value = _videoMeta;
  currentStep.value = STEP_2;
}

function onVideoUploaded(_filename) {
  filename.value = _filename;
  currentStep.value = STEP_3;
}

function onPromptSet(success) {
  if (!success) {
    console.log('Prompt not set');
    return;
  }
  router.back();
}
</script>

<template>
  <div>
    <video-card :metadata="videoMeta" :url="url" />

    <div v-if="state == STATE_IDLE" class="mt-8 pt-8 border-t border-gray-300">
      <p class="mb-4">
        Please upload the video or audio file of that content. <span class="font-bold">The file has to be the same duration as the original video.</span>
      </p>
      <input
        ref="uploader"
        type="file"
        accept="video/*"
        @change="onFileUploadChange"
      >
    </div>

    <div v-else-if="state == STATE_UPLOADING" class="mt-4">
      <p class="text-sm font-medium text-gray-900">Uploading file...</p>
      <div class="mt-4" aria-hidden="true">
        <div class="overflow-hidden rounded-full bg-gray-200">
          <div class="h-2 rounded-full bg-indigo-600" :style="{ width: progressBarWidth }"></div>
        </div>
      </div>
    </div>

    <div v-else-if="state == STATE_ERROR" class="mt-4">
      <div class="rounded-md bg-red-50 p-4">
        <div class="flex">
          <div class="shrink-0">
            <svg class="size-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
              <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-red-800">There was an error</h3>
            <div class="mt-2 text-sm text-red-700">
              {{ errorMessage }}
              <br><br>
              <button class="underline text-blue-400" @click.prevent="reset">Try upload again?</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="state == STATE_DURATION_MISMATCH" class="mt-4">
      <div class="rounded-md bg-yellow-50 p-4">
        <div class="flex">
          <div class="shrink-0">
            <svg class="size-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
              <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-yellow-800">Duration mismatch</h3>
            <div class="mt-2 text-sm text-yellow-700">
              The URL you have provided points out to a video that has a duration <span class="font-bold">{{ urlVideoDuration }}</span> while the file
              you have uploaded has duration of <span class="font-bold">{{ fileVideoDuration }}</span>. Please make sure you have uploaded the correct file.
              <br><br>
              <button class="underline text-blue-400" @click.prevent="reset">Try upload again?</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="state == STATE_ERROR" class="mt-4">
      <div class="text-red-500">{{ errorMessage }}</div>
    </div>

  </div>
</template>

<script setup>
import { ref, shallowRef, computed } from 'vue';
import { Duration } from 'luxon';
import { post } from '../useApi';
import { uploadMultiple, uploadSingle } from '../fileTools';
import VideoCard from './VideoCard.vue';

const props = defineProps({
  url: {
    type: String,
    required: true
  },
  videoMeta: {
    type: Object,
    required: true
  }
});

const emit = defineEmits([ 'next-step' ]);

const lastPercentComplete = shallowRef(0);

const progressBarWidth = computed(() => `${lastPercentComplete.value}%`);

const uploader = ref(null);

const STATE_IDLE = 1;
const STATE_ERROR = 2;
const STATE_UPLOADING = 3;
const STATE_DURATION_MISMATCH = 4;

const state = shallowRef(STATE_IDLE);

const errorMessage = shallowRef(null);

const fileVideoDuration = shallowRef(null);
const urlVideoDuration = shallowRef(null);

const MAX_FILE_SIZE_BYTES = 2 * 1024 * 1024 * 1024; // 2 GB

function reset() {
  errorMessage.value = null;
  fileVideoDuration.value = null;
  urlVideoDuration.value = null;
  state.value = STATE_IDLE;
}

function onFileUploadChange(e) {
  e.preventDefault();
  upload(e.target.files[0]);
}

function updateProgress(loaded, total) {
  const percentComplete = Math.round(loaded * 100 / total);
  if (percentComplete === lastPercentComplete.value) {
    return;
  }

  lastPercentComplete.value = percentComplete;
}

async function upload(file) {
  lastPercentComplete.value = 0;
  fileVideoDuration.value = null;
  urlVideoDuration.value = null;

  if (state.value !== STATE_IDLE) {
    return;
  }

  if (!file) {
    return;
  }

  if (!file.type.startsWith('video/')) {
    errorMessage.value = 'Invalid file type';
    return;
  }

  if (file.size > MAX_FILE_SIZE_BYTES) {
    errorMessage.value = 'File is too large';
    return;
  }

  errorMessage.value = null;

  state.value = STATE_UPLOADING;

  const jsonGenerateSignedUrl = await post('/api/upload/prepare', {
    size: file.size
  });

  if (!jsonGenerateSignedUrl?.success) {
    errorMessage.value = "Upload error: " + (jsonGenerateSignedUrl?.error || "Unknown error");
    state.value = STATE_ERROR;
    return;
  }

  // this is a failsafe fix, should never happen; but when this happens, frontend starts and infinite cycle,
  // so we have to protect the user from this
  if (jsonGenerateSignedUrl.isMultipart && jsonGenerateSignedUrl.urls.length === 0) {
    errorMessage.value = "Server returned an empty list of URLs. How could this happen?";
    state.value = STATE_ERROR;
    return;
  }

  let uploadResponse = null;

  if (jsonGenerateSignedUrl.isMultipart) {
    try {
      uploadResponse = await uploadMultiple({
        file,
        chunkSize: jsonGenerateSignedUrl.chunkSize,
        url: jsonGenerateSignedUrl.urls,
        onProgress: updateProgress
      });
    } catch (e) {
      console.log("FAILED");
      console.log(e);
    }

  } else {
    try {
      uploadResponse = await uploadSingle({
        file,
        url: [ jsonGenerateSignedUrl.url ],
        onProgress: updateProgress
      });
    } catch (e) {
      console.log("FAILED");
      console.log(e);
    }
  }

  if (!uploadResponse) {
    errorMessage.value = "Upload error: " + uploadResponse;
    state.value = STATE_ERROR;
    return;
  }

  const jsonFinish = await post('/api/upload/finish', {
    etags: uploadResponse?.etags,
    filename: jsonGenerateSignedUrl.filename,
    uploadId: jsonGenerateSignedUrl.uploadId // can be undefined
  });

  if (!jsonFinish?.success) {
    errorMessage.value = "Upload error: " + (jsonFinish?.error || "Unknown error");
    state.value = STATE_ERROR;
    return;
  }

  const diffSeconds = Math.abs(jsonFinish.durationSeconds - props.videoMeta.durationSeconds);
  if (diffSeconds > 3) {
    fileVideoDuration.value = Duration.fromMillis(jsonFinish.durationSeconds * 1000).toFormat('hh:mm:ss');
    urlVideoDuration.value = Duration.fromMillis(props.videoMeta.durationSeconds * 1000).toFormat('hh:mm:ss'); ;
    state.value = STATE_DURATION_MISMATCH;
    return;
  }

  emit('next-step', jsonGenerateSignedUrl.filename);
}
</script>
